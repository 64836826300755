import React, { useEffect, useState } from 'react';
import { GoogleMap, Polygon, DrawingManager } from '@react-google-maps/api';
import { Card, Stack, Container, Typography, Button } from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Page from '../../../components/Page';
import Page from '../components/Page';
import EditableMapWithPolygon from './EditableMapWithPolygon';
import { addFences, checkPolygon, getGeoFence } from 'src/common/api-service';

const MapWithPolygon = () => {
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAvaiable, setIsAvaiable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [cityName, setCityName] = useState('');
  console.log("citynamw",cityName)




  const handlePolygonComplete = async (polygon) => {
    const paths = polygon.getPath().getArray();
    const polygonCoordinates = paths.map((path) => ({
      lat: path.lat(),
      lng: path.lng(),
    }));

    setPolygons(polygonCoordinates);

    const center = getPolygonCenter(polygonCoordinates);
    const city = await reverseGeocode(center);
        setCityName(city);

    console.log('polygonCoordinates :', polygonCoordinates);
    addFenceData(polygonCoordinates , city);
  };

  const getPolygonCenter = (polygonCoords) => {
    const latSum = polygonCoords.reduce((acc, curr) => acc + curr.lat, 0);
    const lngSum = polygonCoords.reduce((acc, curr) => acc + curr.lng, 0);
    const center = {
        lat: latSum / polygonCoords.length,
        lng: lngSum / polygonCoords.length,
    };
    return center;
};

  const handlePolygonClick = (polygon) => {
    setSelectedPolygon(polygon);
  };

  //   checkPolygon

  const checkPolygonIsAvailable = () => {
    setLoading(true);

    return checkPolygon().then(
      (res) => {
        console.log('res :', res);
        setIsAvaiable(res.data.is_available);
        if (res.data.is_available == true) {
          getGeoFenceData();
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };
  const getGeoFenceData = () => {
    setLoading(true);

    return getGeoFence().then(
      (res) => {
        console.log('res :', res);
        setPolygons(res.data.data.polygon);
        setLoading(false);

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const reverseGeocode = async (location) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBtNweJE_bDxUtdLyBbfDsJB7P7ap3OsCQ`);
    const data = await response.json();
    const city = data.results[0].address_components.find(component => component.types.includes('locality'));
    return city ? city.long_name : '';
};

  const addFenceData = (polygonCoordinates , city) => {
    setLoading(true);
    const payload = {
      // "name": "Rajarampuri",
       "name" : city,
      "polygon": polygonCoordinates
    };
    return addFences(payload).then(
      (res) => {
        console.log('res :', res);
        getGeoFenceData();
        setLoading(false);
        setIsAvaiable(true)

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  }

  useEffect(() => {
    checkPolygonIsAvailable();
  }, [])

  return (


    <>

      {/* <Button variant="contained"  onClick={()=>{setIsEditable(true)}}>Edit</Button>

     
            <div style={{ height: '600px', width: '100%' }}>
            <GoogleMap
              center={{ lat: 16.7050, lng: 74.2433 }} // Initial map center (New York City coordinates)
              zoom={12} // Initial zoom level
              mapContainerStyle={{ height: '100%', width: '100%' }}
            >
              {polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  paths={polygon}
                  onClick={() => handlePolygonClick(polygon)}
                />
              ))}
              <DrawingManager
                onLoad={(drawingManager) => {
                  drawingManager.setOptions({
                    drawingMode: 'polygon',
                    drawingControl: true,
                    drawingControlOptions: {
                      drawingModes: ['polygon'],
                    },
                  });
                }}
                onPolygonComplete={handlePolygonComplete}
              />
            </GoogleMap>
         
              </div> */}

      {/* {isEditable ? (<EditableMapWithPolygon />) : ( */}
        <Page title="User">
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h4" gutterBottom>
                Geo Fence
              </Typography>
            </Stack>
            {/* {
              isAvaiable &&
              <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                <Button variant="contained" onClick={() => { setIsEditable(true) }}>Edit</Button>
              </Stack>
            } */}
            <Card sx={{ padding: "1rem" }}>
              {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
              {
                isAvaiable ? (
                  <>
                  <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                  {/* <Button variant="contained" onClick={() => { setIsEditable(true) }}>Edit</Button> */}
                  <Button size='small' variant="contained" onClick={() => { setIsAvaiable(false) }}>Edit</Button>
                </Stack>
                  <EditableMapWithPolygon polygons={polygons} />
                  </>
                ) : (
                  <div style={{ height: '600px', width: '100%' }}>
                     <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                      <Button  size='small' variant='contained' onClick={()=> setIsAvaiable(true)}>Back</Button>
                </Stack>
                    <GoogleMap
                      center={{ lat: 16.7050, lng: 74.2433 }}
                      zoom={12}
                      mapContainerStyle={{ height: '100%', width: '100%' }}
                    >
                      {polygons.map((polygon, index) => (
                        <Polygon
                          key={index}
                          paths={polygon}
                          onClick={() => handlePolygonClick(polygon)}
                        />
                      ))}
                      <DrawingManager
                        onLoad={(drawingManager) => {
                          drawingManager.setOptions({
                            drawingMode: 'polygon',
                            drawingControl: true,
                            drawingControlOptions: {
                              drawingModes: ['polygon'],
                            },
                          });
                        }}
                        onPolygonComplete={handlePolygonComplete}
                      />
                    </GoogleMap>

                  </div>
                )
              }

            </Card>
          </Container>
        </Page>

      {/* )} */}

    </>
  );
};

export default MapWithPolygon;
