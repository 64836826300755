/* eslint-disable camelcase */

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';

import SearchIcon from '@material-ui/icons/Search';
import { BookingUpcomingListHead } from '../sections/@dashboard/bookingUpcoming';

import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from 'antd';
import * as XLSX from 'xlsx';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

// mock
import { getGroomingBookingUpcomingData, getRazorPayOrdersData } from '../common/api-service';
import BackButton from '../components/BackButton';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'order_id', label: 'Order Id', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },





  // { id: 'parent_name', label: 'Customer Name', alignRight: false },
  // { id: 'parent_name', label: 'Customer Contact', alignRight: false },


  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function BookingUpcoming() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);



  // const datefilterhandleChange = (props) => {
  //     if (props) {
  //         const [start, end] = props;
  //         setFromDate(start);
  //         setToDate(end);
  //     } else {
  //         setFromDate('');
  //         setToDate('');
  //     }
  // };


  const razorPayOrderDate = () => {
    setLoading(true);
    const payload = page;
    return getRazorPayOrdersData(payload, searchQuery, rowsPerPage).then(
      (res) => {
        setData(res.data.data);
        // setFilteredData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState('created_at');
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    razorPayOrderDate();
  }, [offset, page, searchQuery, rowsPerPage, fromDate, toDate, selectedDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleReferenceID = (_id, booking_ref_id) => {
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("upcomingRefId", _id);
    localStorage.setItem("s1", "Upcoming");

  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;





  // const handleExportToExcel = () => {
  //     const formattedData = formatDataForExport();
  //     const ws = XLSX.utils.aoa_to_sheet([TABLE_HEAD.map((head) => head.label), ...formattedData]);
  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'GroomingBookingUpcoming');
  //     XLSX.writeFile(wb, 'GroomingBookingUpcoming.xlsx');
  // };

  const ExcelButton = styled(Button)({
    backgroundColor: '#217346', // Excel logo color
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#217346', // Hover color
    },
  });


  const handleKeyPress = (e) => {
    // if (e.key === 'Enter') {

    //   handleSearch();
    // }
  };
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            RazorPay Order Data
          </Typography>
          {/* <BackButton /> */}
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center" sx={{ display: 'flex' }}>
                    <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
                        {/* <Grid item xs={8} sm={8} md={8} lg={8} style={{ alignContent: 'right', paddingTop: '5px' }}>
                            <RangePicker onChange={datefilterhandleChange} />

                            <Select
                                style={{ margin: '0px 0px  0px 2px', height: '34px' }}
                                labelId="allocation-label"
                                id="allocation"
                                value={selectedDate}
                                onChange={handleChangeDate}
                            >
                                <MenuItem value="All" disabled>
                                    Select
                                </MenuItem>
                                <MenuItem value="created_at" selected>
                                    Booking Date
                                </MenuItem>
                                <MenuItem value="date">Start Date</MenuItem>
                            </Select>
                        </Grid> */}
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <TextField fullWidth label="Search bookingId" id="fullWidth"
                                InputProps={{
                                    style: { margin: '6px 2px', height: '34px' },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ cursor: 'pointer' }}
                                        // onClick={handleSearch}
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', marginTop: '-4px' },
                                }}
                                value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                                onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9\s]/g, ''))}
                                onKeyDown={handleKeyPress}
                            />
                        </Grid>
                    </Grid>
                </Stack>
        <Card>
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>

              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table size='small'>
                  <BookingUpcomingListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const {
                        _id,
                        pet_name,
                        pet_type,
                        number_of_pets,
                        service_name,
                        date,
                        time_slot,
                        customer_name,
                        payment_method,
                        price_paid,
                        add_ons,
                        package_title,
                        user_phone_no,
                        location,
                        sitter_name,
                        sitter_phone,
                        no_of_sessions,
                        booking_date,
                        completed_sessions,
                        ongoing_session_number,
                        remaining_sessions,
                        pay_on_service,
                        booking_ref_id,
                        service_master_id,
                        session_ref_id,
                        order_id,
                        status,
                        created_at,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell sx={{ textAlign: 'center' }}>
                            {/* <Link className='details-using-ref-id' to={'/dashboard/customerOrderDetails1'} onClick={() => handleReferenceID(_id, booking_ref_id)}> */}
                              {booking_ref_id}
                            {/* </Link> */}
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center' }}>{order_id ? order_id : '-'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{price_paid}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{moment(created_at).format('DD-MM-YYYY')}</TableCell>

                          

                          {/* <TableCell sx={{ textAlign: 'center' }}>{customer_name}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{'8750982543'}</TableCell> */}



                          <TableCell sx={{ textAlign: 'center', padding:'2px' }}>{status ? status : "pending"}</TableCell>



                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            // count={data.length}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

      </Container>
    </Page>
  );
}
