export default {
  API: {
    // --------- Live Url----------
    Url: 'https://api.pawspace.in/', 

    // -----------Local Url For Testing---------
    // Url : 'http://65.0.6.253/'
    // Url : 'https://devapi.pawspace.in/'

    //--------------staging url-------------------------
    // Url: 'https://api.pawspace.in/staging/'


    // Url : 'https://65.0.6.253/'

  },
};
